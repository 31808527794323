import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchesService } from './batches.service';
import { ResponseMessage } from '../../common/response-message';
import { Batches } from './batches';
import { BatchesHeader } from './batchesheader';
import { SUCCESS_MSG } from '../../common/field-name-list';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { BatchModel } from './batch-model';
import { SearchBatchModel } from './search-batches';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['../../../assets/css/bootstrap.css', '../../../assets/css/bil-style.css','../../../../node_modules/font-awesome/css/font-awesome.css','batches.component.css']
})

export class RDSBatchesComponent implements OnInit {
  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;

  @ViewChild('myForm', { static: false }) ngForm: NgForm;

  @ViewChild('group', { static: false }) groupVariable: ElementRef;
  batchOrderLine: Batches[] = [];
  val: boolean = false;
  conceptList: any;
  responseMessage: string;
  errMsg:string="";
  batchOrderList: Batches[] = [];
  createdUser: string;
  batchModel = new BatchModel();
  searchbatchModel = new SearchBatchModel();
  requestParameterModel: RequestParameterModel[];
  enable:boolean=false;
  invokeButton:string= 'disable';
  batches:any;
  batchModel1:any;
  bataYearWeek:any;
  bataYearWeekEnable:boolean=false;
  // batches = [
  //   { id: 'm', name: 'JDE To PO Interface', filename: 'JDE_PO_Interface' },
  //   { id: 'i', name: 'Article Master Upload', filename: 'ART.BAC' },
  //   { id: 'i', name: 'Category Master Upload', filename: 'CTGM.TXT' },
  //   { id: 'i', name: 'Party Master Upload', filename: 'PARTYO.TXT' },
  //   { id: 'i', name: 'Shop Master Upload', filename: 'SA002.TXT' },
  //   { id: 'i', name: 'Week Master Upload', filename: 'WEEKM.TXT' },
  //   { id: 'i', name: 'State Master Upload', filename: 'STATMAST.TXT' },
  //   { id: 'm', name: 'OTB To PO Interface', filename: 'OTB' },
  //   { id: 'r', name: 'RDS To PO Interface', filename: 'ORDFL' },
  //   { id: 'r', name: 'DMS To PO Interface', filename: 'DMS' },
  //   { id: 'r', name: 'Vendor Supply To PO Interface', filename: 'SHIP_VENDOR' },
  //   { id: 'm', name: 'Unit Supply To PO Interface', filename: 'SHIP_FACTORY' },
  //   { id: 'i', name: 'BARCODE To PO Interface', filename: 'BARCODE_TO_PO_TXT.txt' },
  //   { id: 'o', name: 'PO To RIMS Onway Interface', filename: '' },
  //   { id: 'o', name: 'PO To RIMS Onorder Interface', filename: '' },
  //   { id: 'o', name: 'PO To OTB Interface', filename: '' },
  //   { id: 'o', name: 'PO To RDS Interface', filename: '' },
  //   { id: 'o', name: 'PO To DMS Interface', filename: '' },
  //   { id: 'o', name: 'PO To BARCODE Order Master Interface', filename: '' },
  //   { id: 'o', name: 'PO To BARCODE Order Interface', filename: '' },
  //   { id: 'o', name: 'PO To PROMPS Interface', filename: '' },
  //   { id: 'o', name: 'PO To WMS 680 Interface', filename: '' },
  //   { id: 'o', name: 'Cancellation Batch', filename: '' }
  // ];
  // batches = [];

  constructor(private _router: Router, private batchServices: BatchesService,
    private fb: FormBuilder, private authentic: AuthenticationService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    this.invokeButton = 'disable';
    this.bataYearWeekEnable=false;
    this.errMsg="";
    this.bataYearWeek="";
    console.log(this.invokeButton);
    console.log(this.batches);
    let batchInput=[{
      "name":"itfModule",
      "value":"RDS"
  }]
  this.batchServices.getRDSBatches(batchInput).subscribe(
    (res: any) => {
      console.log(res);
      this.batches=res.data;
      console.log(this.batches);
    });
    let sessUsername = sessionStorage.getItem('currentUser');
    this.createdUser = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    console.log(this.createdUser);
    let detail = [{
      "name": "userEmail",
      "value": this.createdUser
    }]

    // this.batchServices.getConceptCode(detail).subscribe(
    //   (response1: BatchesHeader) => {
    //     console.log(response1);
    //     this.conceptList = response1.dataList;
    //   });

    // this.batchServices.getOrderType().subscribe(
    //   (response: Batches) => {
    //     this.batchOrderLine.push(response);
    //     this.batchOrderLine[0].configParamModels.forEach(
    //       element => {
    //         if (element.paramName === 'order_type') {
    //           console.log(element);
    //           this.batchOrderList.push(element);
    //         }
    //       });
    //   }
    // )
  }

  getWeekNumber = function() {
  //define a date object variable that will take the current system date
  let todaydate:any = new Date();

  //find the year of the current date
   let oneJan:any =  new Date(todaydate.getFullYear(), 0, 1);
    // console.log(oneJan);
    let fullYear:string=todaydate.getFullYear();
    // console.log(fullYear);
   // calculating number of days in given year before a given date
   var numberOfDays =  Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000));
  //  console.log(numberOfDays);
   // adding 1 since to current date and returns value starting from 0
   let result=  Math.ceil(( todaydate.getDay() + 1 + numberOfDays) / 7);
   let finalWeek:string = result.toString();
   if(result.toString().length == 1){
    finalWeek = '0' + result.toString();
   }
  //  console.log(result);
  return fullYear+finalWeek.toString();
  }
  onInvoke() {
    this.errMsg="";
    console.log(this.bataYearWeek);
    if(this.bataYearWeekEnable){
      if(this.bataYearWeek == null || this.bataYearWeek == undefined || this.bataYearWeek == '')
      {
        this.errMsg="Please select Bata Year Week";
        return false;
      }else{
        let usrInpt=this.bataYearWeek;
        let curDt=parseInt(this.getWeekNumber());
        console.log(this.getWeekNumber());
        if(usrInpt>curDt)
        {
          this.errMsg="Bata Year Week "+usrInpt+ " should not be greater than current year week "+curDt;
          console.log("Bata Year Week should not be greater than current year week :- "+curDt);
          return false;
        }
      }
    }


    this.responseMessage = '';
    this.invokeButton = 'enable';
    console.log('Batch Invoking Starts here');
    console.log(this.batchModel1);
    if (this.batchModel.name !== 'Cancellation Batch') {
          // this.batchServices.getUploadWithoutFilename(this.batchModel1.itfKey).subscribe();
          if(this.bataYearWeekEnable){
            let fileNames: any = {
              "bataYearWeek": this.bataYearWeek
            };
            this.batchServices.getUploadWithFilename(fileNames, this.batchModel1).subscribe();
          } else if (this.batchModel1 == 'RM_40_OUTBOUND_JOB' || this.batchModel1 == 'SHORTAGE_ADJUSTMENT_JOB' ||
            this.batchModel1 == 'INTER_TRANSFER_RIMS_OUTBOUND'){
            let param: any = {
              "invokedBy": this.createdUser
            };
            this.batchServices.getUploadWithFilename(param, this.batchModel1).subscribe();
          }
          else{
             this.batchServices.getUploadWithoutFilename(this.batchModel1).subscribe();
          }


          this.responseMessage = 'Batch invoked successfully.please check Batch Status to know the progress';
      // this.batches.forEach(
      //   batch => {
      //     // console.log(batch);
      //     if (this.batchModel.name === batch.name && batch.id === 'i') {
      //       let fileNames: any = {
      //         "fileName": batch.filename
      //       };
      //       this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
      //       this.responseMessage = 'Batch Invoked Successfully';
      //     }
      //     else if (this.batchModel.name === batch.name && batch.id === 'o') {
      //       this.batchServices.getUploadWithoutFilename(batch.name).subscribe();
      //       this.responseMessage = 'Batch Invoked Successfully';
      //     }
      //     else if (this.batchModel.name === batch.name && batch.id === 'r') {
      //       let fileNames: any = {
      //         "uploadKeyValues": batch.filename,
      //         "uploadedBy": this.createdUser
      //       };
      //       this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe(
      //         (response: any) => {
      //           // if (response.status === 'Success') {
      //           //   this.responseMessage = response.message;
      //           // }
      //           // else {
      //             this.responseMessage = response.message;
      //           // }
      //         },
      //         (err) => {
      //           this.responseMessage = err;
      //         }
      //       );
      //     }
      //     else {
      //       if (this.batchModel.name === batch.name && batch.id === 'm') {
      //         let fileNames: any = {
      //           "uploadKeyValues": batch.filename,
      //           "uploadedBy":this.createdUser
      //         };
      //         this.batchServices.getUploadWithFilename(fileNames, batch.name).subscribe();
      //         this.responseMessage = 'Batch Invoked Successfully';
      //       }
      //     }
      //   }
      // );
    }
    else {
      this.val = true;
    }
    // this.invokeButton = 'disable';
    console.log('Batch Invoking Ends here');
  }

  // onSubmit() {
  //   this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchbatchModel);
  //   console.log('sending data' + this.requestParameterModel);
  //   this.batchServices.getSubmit(this.requestParameterModel).subscribe(
  //     (response: ResponseMessage) => {
  //       if (response != null && response.status != null && SUCCESS_MSG === response.status.toLowerCase()) {
  //         this.responseMessage = 'Data saved successfully';
  //       }
	//  this.searchbatchModel.conceptCode='';
  //        this.searchbatchModel.orderType='';
  //        this.enable=false;
  //     }
  //   )
  // }

  onChange() {
    console.log(this.batchModel1);
    this.responseMessage = '';
    this.bataYearWeek="";
    this.errMsg="";
    if(this.batchModel1==='STOCK_VALUATION_OUTBOUND_JOB'){
      this.bataYearWeekEnable=true;
    }else{
      this.bataYearWeekEnable=false;
    }
    if(this.batchModel1 === undefined){
      this.invokeButton = 'disable';
      this.val = false;
    }else{
      this.invokeButton = 'enable';
      this.val = true;
    }


  }

  onClickBack() {
    this._router.navigate(['']);
  }
 checkValue(){
    if(this.searchbatchModel.conceptCode !== '' && this.searchbatchModel.conceptCode !== null && this.searchbatchModel.conceptCode !== undefined){
      if(this.searchbatchModel.orderType !== '' && this.searchbatchModel.orderType !== null && this.searchbatchModel.orderType !== undefined){
        this.enable = true;
      }
    }
    // this.searchbatchModel
  }

}
