import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BilApiUrl } from '../common/bil-api-url';

@Injectable({
  providedIn: 'root'
})
export class BatchesService {
  submitCodeurl: string = BilApiUrl.PO_AUTOCANCELLATION;
  orderTypeurl: string = BilApiUrl.FETCH_CONFIG_DETAILS;
  conceptCodeurl: string = BilApiUrl.CONCEPT_MASTER;
  URL: string;
  batchesUrl = [
    { name: 'JDE To PO Interface', url: BilApiUrl.JDE_PO_UPLOAD },
    { name: 'Article Master Upload', url: BilApiUrl.ADD_ARTICLE_MASTER },
    { name: 'Category Master Upload', url: BilApiUrl.ADD_CATEGORY_MASTER },
    { name: 'Party Master Upload', url: BilApiUrl.PARTY_MASTER_UPLOAD },
    { name: 'Shop Master Upload', url: BilApiUrl.ADD_SHOP_MASTER },
    { name: 'Week Master Upload', url: BilApiUrl.ADD_WEEK_MASTER },
    { name: 'State Master Upload', url: BilApiUrl.ADD_STATE_MASTER },
    { name: 'OTB To PO Interface', url: BilApiUrl.OTB_PO_INTERFACE },
    { name: 'RDS To PO Interface', url: BilApiUrl.RDS_PO_INTERFACE },
    { name: 'DMS To PO Interface', url: BilApiUrl.DMS_PO_INTERFACE },
    { name: 'Vendor Supply To PO Interface', url: BilApiUrl.SUPPLIER_PO_INTERFACE },
    // { name: 'Unit and SVS Supply to PO interface', url: BilApiUrl.SUPPLIER_PO_INTERFACE },
    { name: 'BARCODE To PO Interface', url: BilApiUrl.BARCODE_PO_INTERFACE },
    { name: 'PO To RIMS Onway Interface', url: BilApiUrl.PO_RIMS_ONWAY_INTERFACE },
    { name: 'PO To RIMS Onorder Interface', url: BilApiUrl.PO_RIMS_ONORDER_INTERFACE },
    { name: 'PO To OTB Interface', url: BilApiUrl.PO_TO_OTB_INTERFACE },
    { name: 'PO To RDS Interface', url: BilApiUrl.PO_TO_RDS_INTERFACE },
    { name: 'PO To DMS Interface', url: BilApiUrl.PO_TO_DMS_INTERFACE },
    { name: 'PO To BARCODE Order Master Interface', url: BilApiUrl.PO_TO_BARCODE_ORDER_MASTER_INTERFACE },
    { name: 'PO To Tableau Interface', url: BilApiUrl.PO_TO_BARCODE_ORDER_INTERFACE },
    { name: 'PO To PROMPS Interface', url: BilApiUrl.PO_TO_PROMPS_INTERFACE },
    { name: 'PO To WMS 680 Interface', url: BilApiUrl.PO_TO_WMS_INTERFACE },//
    { name: 'Requisition Upload Batch', url: BilApiUrl.REQUISITION_UPLOAD_BATCH },
    { name: 'Approve Po Batch', url: BilApiUrl.APPROVE_PO_BATCH },
    { name: 'Po Pdf Batch', url: BilApiUrl.PO_PDF_BATCH },
    { name: 'Auto PO Invoke', url: BilApiUrl.AUTO_PO_INVOKE}
  ];
  constructor(private _http: HttpClient) { }

  getOrderType() {
    return this._http.get(this.orderTypeurl);
  }

  getConceptCode(details: any) {
    return this._http.post(this.conceptCodeurl, details);
  }

  getSubmit(details: any) {
    return this._http.post(this.submitCodeurl, details);
  }

  getUploadWithFilename(fileName: any, batchname) {
    this.URL = '';
    this.batchesUrl.forEach(
      holdingObj => {
        if (batchname == holdingObj.name) {
          this.URL = holdingObj.url;
        }
      }
    );
    console.log('filename' + JSON.stringify(fileName));
    console.log('URL' + this.URL);
    return this._http.post(this.URL, fileName);
  }

  getUploadWithoutFilename(batchname) {
    this.URL = '';
    this.batchesUrl.forEach(
      holdingObj => {
        if (batchname == holdingObj.name) {
          this.URL = holdingObj.url;
        }
      }
    );
    console.log('URL' + this.URL);
    return this._http.post(this.URL, null);
  }

}

