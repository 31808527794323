import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PosShortageAdjustmentService } from "./pos-shortage-adjustment.service";
import { PosHeaderList } from "./pos-header-list";
import { PosRdsShortageAdjustmentResponse } from "./pos-rds-shortage-adjustment-response";

@Component({
  selector: "app-pos-shortage-adjustment",
  templateUrl: "./pos-shortage-adjustment.component.html",
  styleUrls: [
    "../../../assets/css/bootstrap.css",
    "../../../assets/css/rds-bil-style.css",
    "../../../../node_modules/font-awesome/css/font-awesome.css",
    "./pos-shortage-adjustment.component.css",
  ],
})
export class PosShortageAdjustmentComponent implements OnInit {
  @ViewChild("loaDer", { static: false }) loaDer: ElementRef;
  @ViewChild("closeBtn", { static: false }) closeBtn: ElementRef;
  @ViewChild("closeBtn1", { static: false }) closeBtn1: ElementRef;
  @ViewChild("closeBtn2", { static: false }) closeBtn2: ElementRef;
  @ViewChild("shortageBtn", { static: false }) shortageBtn: ElementRef;

  searchInvoiceCriteriaList = new PosHeaderList();
  InvoiceType: string;
  status: string;
  userCode: string;
  responseMessage: string;
  responseMessage1: string;
  responseMessage2: string;
  shortageAdjustmentList: any[];
  shortageAdjustmentDrpdwn: any[];
  shortageAdjustmentFinalDrpdwn: any[];
  shortageAdjustmentFinalDrpdwnCheck: any;
  posStockAdjustmentForm: FormGroup;
  selectedAdjustment: any[];
  selectedAdjustmentFinal: any[];
  performAdjustmentList: any = {};
  selectedAdjustmentDisplayList: any[];
  headerDisplayList: any[];
  performAdjustmentArray: any[];
  segment: string;
  shortageAdjustment: any[];
  remarks: string;
  isShowDiv = true;
  selectedRow: any[];
  artNumber: string;
  grnId: any;
  assortArray: any[];
  assortData: any = {};
  groupCode: string;
  parentId: number;
  childId: number;
  childrenId: number;
  arr = ["fromDate", "toDate"];
  dateOfYear1 = "";
  dateTime = new Date();
  shortAdjDebitedToCodeDisplay: number;
  performRowIndex: any;
  listObj: any = [];
  consignorCode: string;
  consignorName: string;
  inputboxValidation: number;
  isSave: number = 0;
  display = "none";
  display2 = "none";
  display3 = "none";
  display4 = "none";
  display5 = "none";
  traporterBoxShow: Boolean = true;
  grnType: any;
  inventoryType: any;
  grnIndex: number;
  remark: string = "";   // variable to store remarks
  InvoiceNumber: string;
  details: any;
  ArtFlag: string = 'N';
  isButtonDisabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private PosShortageAdjustmentService: PosShortageAdjustmentService
  ) {
    let sessUsername = sessionStorage.getItem("currentUser");
    this.userCode = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.dateTime.setDate(this.dateTime.getDate());
    this.buildForm();
  }

  buildForm(): void {
    this.posStockAdjustmentForm = this.fb.group({
      InvoiceNumber: [""],
      InvoiceType: [""],
      status: [""],
      InvoiceDatefrom: [""],
      InvoiceDateTo: [""],
      userCode: [this.userCode],
    });
  }

  ngOnInit() {
    this.assortArray = [];
    this.responseMessage2 = "";

    setTimeout(() => {
      //this.maxDate = new Date();
      if (this.selectedRow == undefined) {
        this.selectedRow = [];
      }
      if (this.selectedAdjustment == undefined) {
        this.selectedAdjustment = [];
      }
      if (this.listObj == undefined) {
        this.listObj = [];
      }
      this.shortageAdjustment = null;
      this.remarks = "";
      this.loaDer.nativeElement.style.display = "none";
    }, 100);
  }

  onReset() {
    this.posStockAdjustmentForm.reset();
    setTimeout(() => {
      this.buildForm();
    }, 500);
    this.shortageAdjustmentList = [];
    this.selectedAdjustment = [];
    this.selectedAdjustmentDisplayList = [];
    this.responseMessage = "";
    this.responseMessage1 = "";
  }

  changeDate(value, key) {
    this.responseMessage = "";
    const myDate = new Date(value);
    const mnth = ("0" + (myDate.getMonth() + 1)).slice(-2);
    const day = ("0" + myDate.getDate()).slice(-2);
    this.dateOfYear1 = [day, mnth, myDate.getFullYear()].join("/");
    if (key === "fromDate") {
      this.posStockAdjustmentForm.controls["InvoiceDatefrom"].setValue(
        this.dateOfYear1
      );
    } else {
      this.posStockAdjustmentForm.controls["InvoiceDateTo"].setValue(
        this.dateOfYear1
      );
    }
  }

  searchStockAdjustment() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 3000);
    if (
      this.posStockAdjustmentForm.controls["InvoiceNumber"].value == "" &&
      this.posStockAdjustmentForm.controls["InvoiceType"].value == "" &&
      this.posStockAdjustmentForm.controls["status"].value == "" &&
      this.posStockAdjustmentForm.controls["InvoiceDatefrom"].value == "" &&
      this.posStockAdjustmentForm.controls["InvoiceDateTo"].value == ""
    ) {
      this.responseMessage = "Please select any search criteria.";
      return false;
    } else {
      const startDateFC = this.posStockAdjustmentForm.controls["InvoiceDatefrom"].value;
      const endDateFC = this.posStockAdjustmentForm.controls["InvoiceDateTo"].value;
      const FromDate = startDateFC.split("/");
      const startDate = new Date(FromDate[2], FromDate[1], FromDate[0]);
      const ToDate = endDateFC.split("/");
      const endDate = new Date(ToDate[2], ToDate[1], ToDate[0]);

      if (startDateFC != "" && endDateFC == "") {
        this.responseMessage = "Please select invoice To Date.";
        return false;
      } else if (startDateFC == "" && endDateFC != "") {
        this.responseMessage = "Please select invoice From Date.";
        return false;
      } else if (startDateFC != "" && endDateFC != "") {
        if (startDate.getTime() > endDate.getTime()) {
          this.responseMessage = "invoice From Date is greater than invoice To Date.";
          return false;
        }
      }
      this.assortArray = [];
      this.selectedRow = [];
      this.shortageAdjustmentList = [];
      this.loaDer.nativeElement.style.display = "block";
      this.listObj = [];
      this.selectedAdjustment = [];
      this.selectedAdjustmentDisplayList = [];
      this.PosShortageAdjustmentService.getStockAdjustmentDetails(
        this.posStockAdjustmentForm.value
      ).subscribe(
        (response: any) => {
          this.loaDer.nativeElement.style.display = "none";
          // console.log(response, "+++");
          // return
          if (response.dataList != null && response.dataList.length !== 0) {
            for (let x = 0; x < response.dataList.length; x++) {
              if (
                this.listObj.findIndex(
                  (i) =>
                    i.invoiceNumber == response.dataList[x]["invoiceNumber"]
                ) == -1
              ) {
                this.listObj.push({
                  invoiceNumber: response.dataList[x]["invoiceNumber"],
                  rdcno: response.dataList[x]["rdcno"],
                  invoiceType: response.dataList[x]["invoiceType"],
                  shopNo: response.dataList[x]["shopNo"],
                  bookDt: response.dataList[x]["bookDt"],
                  children: [],
                });
              }
              let index = this.listObj.findIndex(
                (i) => i.invoiceNumber == response.dataList[x]["invoiceNumber"]
              );
              this.listObj[index]["children"].push(response.dataList[x]);
            }
            console.log(this.listObj);
            if (this.listObj.length > 0) {
              for (let i = 0; i < this.listObj.length; i++) {
                this.performAdjustmentList[i] = {};
                this.performAdjustmentList[i][1] = {};
                this.performAdjustmentList[i][2] = {};
                if (this.listObj[i].children.length > 0) {
                  for (let j = 0; j < this.listObj[i].children.length; j++) {
                    this.performAdjustmentList[i][1][j] = {};
                    this.performAdjustmentList[i][2][j] = {};
                    let articleNumber =
                      this.listObj[i].children[j].articleNumber;
                    this.performAdjustmentList[i][1][articleNumber] = {};
                    this.performAdjustmentList[i][2][articleNumber] = {};
                    this.performAdjustmentList[i][1][j]["isSave"] = 0;
                    this.performAdjustmentList[i][2][j]["isSave"] = 0;
                    this.performAdjustmentList[i][1][j]["openPopup"] = 0;
                    this.listObj[i].children[j]["quantity"] =
                      this.listObj[i].children[j].quantity;
                  }
                }
              }
            }
            this.shortageAdjustmentList = response.dataList;
            console.log(this.shortageAdjustmentList);
          } else {
            this.responseMessage = "No Data Found";
          }
        },
        (err) => {
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = "none";
        },
        () => {
          this.loaDer.nativeElement.style.display = "none";
        }
      );
    }
  }

  characterCheck(event, name) {
    var regExpr = /[^a-zA-Z0-9-. ]/g;
    var userText = event.target.value;
    if (userText != "") {
      if (name == "invoiceNo") {
        this.posStockAdjustmentForm.controls["InvoiceNumber"].setValue(
          userText.replace(regExpr, "")
        );
      }
    }
  }

  showHideDiv(index) {
    let arr_index = this.selectedRow.indexOf(index);
    if (arr_index !== -1) {
      this.selectedRow.splice(arr_index, 1);
      document.getElementById("showHideDiv" + index).classList.remove("active");
    } else {
      this.selectedRow.push(index);
      document.getElementById("showHideDiv" + index).classList.add("active");
    }
  }


  handlePosRdsStatusChange(data: any, type: string): void {
    console.log("Transporter button clicked for:", data);
    const transporterData = {
      InvoiceNumber: data.invoiceNumber,
      ArticleNumber: data.articleNumber,
      ArticleSize: data.articleSize,
      ShortageExcessType: type,
      remarks: data.remarks,
      reqId: data.reqid,
    };
    this.PosShortageAdjustmentService.postPOSRDSStatusChange(
      transporterData
    ).subscribe({
      next: (response:any) => {
        console.log(response);
       // data.remarks = '';
        if(response.status.toLowerCase()==='success'){
          // this.searchStockAdjustment();
          data.status = 'IN-PROGRESS';
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  performButtonClick(_invoiceNumber: string, index: number){
    this.listObj[index].isButtonDisabled = true;
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.responseMessage = "";
    }, 5000);
    const body = { invoiceNumber: _invoiceNumber };
    console.log(body);
    this.PosShortageAdjustmentService.PosRdsShortageAdjustment(body).subscribe(
      (response: PosRdsShortageAdjustmentResponse) => {
        // If ArtFlag is coming from the response
        if (response.ArtFlag === 'N'){
          this.responseMessage = "Please make the shortage against all your articles.";
          this.listObj[index].isButtonDisabled = false;
        }else {
          // Success case
          console.log('Success:', response);
          this.responseMessage = response.message;
        }
        this.isButtonDisabled = false;
      },
      (error) => {
        console.error('Error:', error);
        this.responseMessage = "An error occurred during shortage adjustment.";
        this.listObj[index].isButtonDisabled = false;
        this.isButtonDisabled = false;
      }
    );
  }

  isPerformAdjustmentDisabled(listData: any): boolean {
    if (listData.children && listData.children.length > 0) {
      return !listData.children.some(child =>
        (child.status === 'OPEN') ||
        (child.status === 'In-Progress' && child.logfireflag === 'N')
      );
    }
    return true;
  }

  // isPerformAdjustmentDisabled(listData: any): boolean {
  //   if (!listData.children || listData.children.length === 0) {
  //     return false;
  //   }
  //   // Check if all children have status as CLOSED or In-Progress
  //   return listData.children.every((child: any) =>
  //     child.status === 'CLOSED' || child.status === 'In-Progress');
  // }
}
