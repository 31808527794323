import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReportHeader } from './report-header';
import { RdsReportStockadjService } from './rds-report-stockadj.service';
import { ReportGeneration } from '@/common/report-generation';
import { RequestParameterModel } from '../../common/request-parameter-model';
import { UtilsService } from '../../common/utils-service';
import { ReportSearch } from './report-header-search';
import { Concept } from '../../common/concept';
import { ReportNames } from '../../common/reports-names';
import { OrderType } from '../../common/orderType';

@Component({
  selector: 'app-rds-report-stockadj',
  templateUrl: './rds-report-stockadj.component.html',
  styleUrls: ['./rds-report-stockadj.component.css']
})
export class RdsReportSTOCKADJComponent implements OnInit {

  @ViewChild('loaDer', { static: false }) loaDer: ElementRef;
  @ViewChild('myform', { static: false }) myForm: ElementRef;
  enable = false;
  shopEnable = true;
  regionEnable = true;
  distEnable = true;
  destinationEnable = false;
  regions = [{ name: 'North (N)', value: 'N' },
  { name: 'South (S)', value: 'S' },
  { name: 'East (E)', value: 'E' },
  { name: 'West (W)', value: 'W' }];
  reportModel = new ReportHeader();
  requestParameterModel: RequestParameterModel[];
  searchParameterModel = new ReportSearch();
  concepts: Concept[];
  conceptswithAll: any[] = [];
  orderTypeWithAll: any[] = [];
  conceptsForRetail: any[] = [];
  reportNames: ReportNames[];
  username: string;
  coCode: any;
  responseMessage: string;
  sucessMeg1: string;
  asyncSuccess: string = 'Failed';
  loginUserEmail: string;
  generatebutton: string = 'enable';
  sucessMeg: string;
  showDiv = {
    Daily: false,
    Week: false,
    Fortnightly: false,
    Monthly: false
  }
  showDiv1 = {
    Factory: false,
    Vendor: false,
  }
  showReportList = {
    Reportname: false
  }
  fromWeek: string;
  errorMessage: string;
  payloadBataWeek = {};
  selectedValue = 'M';
  fromDate: string;
  arr = ['fromDate', 'toDate'];
  selectedVal: String;
  date: Date;
  toDate: string;
  ShopCodes: any[] = [];
  DistrictCodes: any[] = [];
  names: any[];
  onlyPerfix: any[] = [];
  uniquerper: any[] = [];
  disableReportList = true;
  selectedPrefix: string;
  selectedList: any[] = [];
  uniqueDist: any[] = []

  obj= {
    PIAMR_SC: 'Either "RDC Code" / "Region" is REQUIRED.',
    SAR: 'Either "RDC Code" / "District Code" / "Region" is REQUIRED.',
    POS: 'Either "RDC Code" / "Region" is REQUIRED.'
};

  constructor(private _router: Router, private _generateReportService: RdsReportStockadjService,
    private _utilsService: UtilsService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loaDer.nativeElement.style.display = 'none';
    }, 100);

    this.reportModel.reportFormatType = 'xlsx';

    let sessUsername = sessionStorage.getItem('currentUser');
    var conceptQueryMas = [{ 'name': 'email', 'value': JSON.parse(sessUsername).groupAssignmentModels[0].userCode }];
    this.username = JSON.parse(sessUsername).groupAssignmentModels[0].userCode;
    this.coCode = JSON.parse(sessUsername).groupAssignmentModels[0].concept.split(',');
    this.loginUserEmail = JSON.parse(sessUsername).groupAssignmentModels[0].userEmail
    this._generateReportService.getReports().subscribe(
      (data: ReportNames[]) => {
        this.reportNames = data;
        this.reportNames.forEach(element => {
          let reportPrefixOnly: {} = element.reportName.split("_");
          let rsp_prf = {
            "perfix": reportPrefixOnly[0],
            "reportName": reportPrefixOnly[1],
            "reportKey": element.reportKey
          };
          this.onlyPerfix.push(rsp_prf);
        });
        this.uniquerper = [...new Set(this.onlyPerfix.map(x => x.perfix))];
        this.onSelectPerfix("STOCK ADJ");
      }
    )

    this._generateReportService.getConcepts(conceptQueryMas).subscribe(
      (data: any) => {
        this.concepts = data.dataList;
        this.concepts.forEach(
          concept => {
            this.conceptswithAll.push(concept);
            if (concept.division == 'RETAIL' && concept.concCode != 'N') {
              this.conceptsForRetail.push(concept);
            }
          }
        );
        let conc = new Concept();
        conc.concCode = 'ALL';
        conc.concept = 'ALL';
        conc.description = 'ALL CONCEPT';
        this.conceptswithAll.unshift(conc);
        //this.searchParameterModel.concept = 'ALL';
      }
    )
  }
  clearErrorMsg() {
    this.errorMessage = '';
    this.responseMessage = '';
  }

  onSelectConcept(value) {
    this.loaDer.nativeElement.style.display = 'block';
    this.clearErrorMsg();
    this.searchParameterModel.concept = undefined;
    this.ShopCodes = [];
    this.DistrictCodes = [];
    let divisionCode: string;
    this.concepts.filter(cs => cs.concCode === this.searchParameterModel.conceptCode).forEach(
      div => {
        if (value != 'ALL') {
        this.searchParameterModel.concept = (div.concCode +'-'+ div.description);
        }
        if (div.division === 'RETAIL') {
          divisionCode = '5';
        } else {
          divisionCode = '4';
        }
      }
    )
    let detail: any;
    if (value != 'ALL') {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': divisionCode,
        'userCode': this.username,
        'conceptCode': this.searchParameterModel.conceptCode
      }
    }
    else {
      detail = {
        'shopCloseYrWk1': '0',
        'shopAgencyCode': '5',
        'userCode': this.username
      }
    }
    const requestParameterModel = this._utilsService.createRequestParametersModelArray(detail);
    console.log(JSON.stringify(requestParameterModel));
    if (value != '0: undefined') {
      this.shopEnable = false;
      this.distEnable = false;
      this.regionEnable = false;
      this._generateReportService.getLocations(requestParameterModel).subscribe(
        (res: any) => {
          this.ShopCodes = [];
          this.uniqueDist = [];
          if (res.dataList != null) {
            res.dataList.forEach(
              data => {
                this.ShopCodes.push(data);
                this.uniqueDist.push(data);
                this.searchParameterModel.regionCode = undefined;
                this.DistrictCodes = [...new Set(this.uniqueDist.map(x => x.districtNumber))];
              }
            );
            // const details =
            // {
            //   "shopNumber": "ALL",
            //   "shopName": "All RDC CODE"
            // };
            // this.ShopCodes.unshift(details);
          }
          this.loaDer.nativeElement.style.display = 'none';
        }
      );
    }
    else {
      this.shopEnable = true;
      this.distEnable = true;
      this.regionEnable = true;
      this.searchParameterModel.regionCode = undefined;
      this.searchParameterModel.toRdcCode = undefined;
      this.searchParameterModel.sourceRdc = undefined;
      this.searchParameterModel.districtCode = undefined;
      this.loaDer.nativeElement.style.display = 'none';
    }
  }

  onClickBack() {
    this._router.navigate(['']);
  }

  public async OnClickGenerate(myForm): Promise<void> {
    this.responseMessage = '';
    this.errorMessage = '';
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.loaDer.nativeElement.style.display = 'block';
    let switchFlag: any;
    let reportKey: any;
    let reportName: any;
    this.sucessMeg = 'Success';
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        switchFlag = report.switchingFlag;
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    this.requestParameterModel = [];
    this.requestParameterModel = this._utilsService.createRequestParametersModelArray(this.searchParameterModel);
    let userDetails = {
      "name": "userId",
      "value": this.username
    }
    this.requestParameterModel.push(userDetails);
    console.log('requesting parameters inside updatedDatalist' + JSON.stringify(this.requestParameterModel));
    let details = new ReportGeneration();
    if (switchFlag == 'N') {
      console.log('Report will get generated online');
      if(this.searchParameterModel.conceptCode != null){
      details.reportFormatType = this.reportModel.reportFormatType;
      details.reportInputRequestList = this.requestParameterModel;
      this.callingServiceWithoutBlob(details);
      this.loaDer.nativeElement.style.display = 'none';
    } else{
      this.errorMessage = 'Concept Code is REQUIRED.';
        this.loaDer.nativeElement.style.display = 'none';
    }
  }
    else {
      console.log('Report will get generated offline');
      let detail = {
        "name": "reportKey",
        "value": reportKey
      }
      let nameDetail = {
        "name": "reportName",
        "value": reportName
      }
      this.requestParameterModel.push(detail);
      this.requestParameterModel.push(nameDetail);
      console.log(JSON.stringify(this.requestParameterModel));
      this._generateReportService.offlineReportGeneration(this.requestParameterModel).subscribe(
        (response: any) => {
          console.log(JSON.stringify(response));
          if (response.status == 'success') {
            // this.responseMessage = response.message;
            this.asyncSuccess = 'success';
            this.responseMessage = "Your report will be generated soon, you will be notified over mail. You can check the progress in View Report Status.";
          }
          else {
            this.asyncSuccess = 'Failed';
            this.responseMessage = response.message;
          }
          this.loaDer.nativeElement.style.display = 'none';
        },
        (err) => {
          this.asyncSuccess = 'Failed';
          this.responseMessage = err;
          this.loaDer.nativeElement.style.display = 'none';
        }
      )
    }
  }

  callingServiceWithoutBlob(details) {
    let reportKey: any;
    let reportName: any;
    this.reportNames.filter(cs => cs.reportKey == this.reportModel.reportKey).forEach(
      report => {
        reportKey = report.reportKey;
        reportName = report.reportName;
      }
    )
    let reportDetails =
    {
      "name": "reportName",
      "value": reportName
    }
    this.requestParameterModel.push(reportDetails);
    let reportKeySelected =
    {
      "name": "reportKey",
      "value": reportKey
    }
    this.requestParameterModel.push(reportKeySelected);
    this.loginUserEmail
    let userSelectedEmail =
    {
      "name": "userEmail",
      "value": this.loginUserEmail
    }
    this.requestParameterModel.push(userSelectedEmail);
    if (this.searchParameterModel.conceptCode === 'ALL') {
      let index = this.requestParameterModel.findIndex(cs => cs.name == 'conceptCode');
      this.requestParameterModel.splice(index, 1);
    }
    details.reportInputRequestList = this.requestParameterModel;
    console.log(JSON.stringify(details));

    let r=new Map(Object.entries(this.obj));

      if (this.searchParameterModel.toShopCode != null || this.searchParameterModel.sourceRdc != null || this.searchParameterModel.toDistrictCode != null || this.searchParameterModel.districtNumber != null || this.searchParameterModel.fromRdcDistrictCode != null ||
        this.searchParameterModel.regionCode != null || this.searchParameterModel.shopCode != null || this.searchParameterModel.rdcNo != null
        || this.searchParameterModel.fromShopCode != null || this.searchParameterModel.rdcCode != null ||
        this.searchParameterModel.fromRdcCode != null || this.searchParameterModel.toRdcCode != null ||
        this.searchParameterModel.shopCityName != null || this.searchParameterModel.districtCode != null) {

        if (this.reportModel.reportKey === 'OGPR') {
          let statusCheck: boolean;
          statusCheck = this.datecheck(this.searchParameterModel.gatePassStartDate, this.searchParameterModel.gatePassEndDate);
          if (statusCheck == true) {
            this.callReportService(details);
          }
        }
        else if (this.reportModel.reportKey === 'DDR' || this.reportModel.reportKey === 'NVR') {
          let statusCheck: boolean;
          statusCheck = this.datecheck(this.searchParameterModel.weekFrom, this.searchParameterModel.weekTo);
          if (statusCheck == true) {
            this.callReportService(details);
          }
        }
        else if (this.reportModel.reportKey === 'CD_FR') {
          let statusCheck: boolean;
          statusCheck = this.datecheck(this.searchParameterModel.yearWeekFrom, this.searchParameterModel.yearWeekTo);
          if (statusCheck == true) {
            this.callReportService(details);
          }
        } else if (this.reportModel.reportKey === 'PIAMR_SC') {
          let statusCheck: string;
         // statusCheck = this.datecheck(this.searchParameterModel.fromDate, this.searchParameterModel.toDate);
         statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.weekFrom, this.searchParameterModel.weekTo,'Year Week From','Year Week To');
          if (statusCheck == 'success') {
            this.callReportService(details);
          }
          else{
            this.errorMessage = statusCheck;
          }
        } else if (this.reportModel.reportKey === 'POS') {
          let statusCheck: string;
         statusCheck = this._utilsService.WeekYearValidationCheck(this.searchParameterModel.weekFrom, this.searchParameterModel.weekTo,'Year Week From','Year Week To');
          if (statusCheck == 'success') {
            this.callReportService(details);
          }
          else{
            this.errorMessage = statusCheck;
          }
        }
          else if (this.reportModel.reportKey === 'SAR') {
          if(this.searchParameterModel.reportPeriodType != null){
          let statusCheck: boolean;
          //statusCheck = this.datecheck(this.searchParameterModel.fromDate, this.searchParameterModel.toDate);
          this.changeSelectedDate(this.searchParameterModel.toDate, this.searchParameterModel.fromDate);
          let frmDateArray: any[] = this.searchParameterModel.fromDate.split('/');
          let fromDateCheck = new Date(frmDateArray[2], frmDateArray[1] - 1, frmDateArray[0]);
          let toDateArray: any[] = this.searchParameterModel.toDate.split('/');
          let toDateCheck = new Date(toDateArray[2], toDateArray[1] - 1, toDateArray[0]);
          statusCheck = this.datecheck(fromDateCheck, toDateCheck);
          if (statusCheck == true) {
            this.callReportService(details);
          }
        }else{
          this.errorMessage = "Type is REQUIRED";
        this.loaDer.nativeElement.style.display = 'none';
        }
        }
        else {
          this.callReportService(details);
        }
      } else {
        this.errorMessage = r.get(this.reportModel.reportKey);
        this.loaDer.nativeElement.style.display = 'none';
      }
  }

  callReportService(details) {
    this._generateReportService.getReportDetailsWithoutBlob(details, this.reportModel.reportKey).subscribe(
      (response: any) => {
        if (response.status == 'Success') {
          this.asyncSuccess = 'success';
          this.responseMessage = 'Report will be generated soon. You will be notified via Email once the report is generated.';
          this.loaDer.nativeElement.style.display = 'none';
          this.generatebutton = 'disable';
          this.delay(5000).then(any => {
            this.generatebutton = 'enable';
          });
        }
        else {
          this.asyncSuccess = 'Failed';
          this.errorMessage = response.message;
        }
        this.loaDer.nativeElement.style.display = 'none';
      },
      (err) => {
        this.asyncSuccess = 'Failed';
        this.errorMessage = err;
        this.loaDer.nativeElement.style.display = 'none';
      });
  }

  changeDate(value, key) {
    this.clearErrorMsg();
    //this.responseMessage = '';
    let myDate = new Date(value);
    let dateOfYear1 = this._utilsService.transformDateSearchchange(myDate);
    if (key == 'fromDate') {
      this.searchParameterModel.gatePassStartDate = dateOfYear1;
    }
    else {
      this.searchParameterModel.gatePassEndDate = dateOfYear1;
    }
  }

  changeSelectedDate(todate, fromdate) {
    //this.responseMessage = '';
    this.clearErrorMsg();
    // let deliveryToDate: any[] = todate.split('/');
    // let etDate = new Date(deliveryToDate[2], deliveryToDate[1], deliveryToDate[0]);
    this.requestParameterModel.filter(cs => cs.name == 'fromDate' || cs.name == 'toDate').forEach(
      each => {
        let deliveryFrmDate: any[] = each.value.split('/');
        let stDate = new Date(deliveryFrmDate[2], deliveryFrmDate[1] - 1, deliveryFrmDate[0]);
        each.value = this._utilsService.transformDateSearchchange(stDate);
      }
    )
  }

  datecheck(fromDate, toDate) {
    this.clearErrorMsg();
    let status: boolean = false;
    if (fromDate != undefined || toDate != undefined) {
      if (new Date(fromDate).getTime() > 0 && new Date(toDate).getTime() > 0) {
        console.log(fromDate);
        console.log(toDate);
        if ((new Date(fromDate).getTime() == new Date(toDate).getTime()) || (fromDate == toDate)) {
          console.log('equal');
          status = true;
        }
        else if ((new Date(fromDate).getTime() < new Date(toDate).getTime())) {
          console.log('greater');
          status = true;
        }
        else {
          console.log('smaller');
          status = false;
          this.errorMessage = "From Date should be smaller than To Date";
          this.loaDer.nativeElement.style.display = 'none';
        }
      }
      else {
        this.errorMessage = "Please select both dates";
        this.loaDer.nativeElement.style.display = 'none';
      }
    }
    else {
      status = true;
    }
    return status;
  }

  onClickReset() {
    this.searchParameterModel = new ReportSearch();
    console.log('reset report search details');
    //this.searchParameterModel.concept = 'ALL';
    this.diableSection();
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.regionEnable = true;
    this.enable = false;
    this.shopEnable = true;
    this.distEnable = true;
    this.searchParameterModel.region = "";
    this.showReportList.Reportname = false;
    this.showDiv1.Factory = false;
    this.showDiv1.Vendor = false;
  }

  setData() {
    console.log('removed');
    this.diableSection();
    this.asyncSuccess = 'Failed';
    this.generatebutton = 'enable';
    this.searchParameterModel = new ReportSearch();
    this.onClickReset();
     //this.searchParameterModel.concept = 'ALL';
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  regionChangeShopCode(regionVal) {
    this.clearErrorMsg();
    if (regionVal != '0: undefined') {
      this.shopEnable = true;
      this.distEnable = true;
    }
    else {
      this.shopEnable = false;
      this.distEnable = false;
    }
  }

  changeDistrictCode(distValue) {
    this.clearErrorMsg();
    if (distValue != '0: undefined') {
      this.regionEnable = true;
      this.shopEnable = true;
    }
    else {
      this.regionEnable = false;
      this.shopEnable = false;
    }
  }

  onBlurCode(value) {
    this.clearErrorMsg();
    if (value != '0: undefined') {
      this.regionEnable = true;
      this.distEnable = true;
    }
    else {
      this.regionEnable = false;
      this.distEnable = false;
    }
  }

  selectedDurationtype(event: any): void {
    console.log(event);
    this.clearErrorMsg();
    this.selectedVal = event;
    console.log(this.searchParameterModel.reportPeriodType);
    // if (this.searchParameterModel.reportPeriodType != undefined) {
    // this.searchParameterModel = new ReportSearch();
    //}
    this.searchParameterModel.periodEndNo = undefined;
    this.searchParameterModel.periodStartNo = undefined;
    this.sucessMeg1 = '';
    this.responseMessage = '';
    if (this.selectedVal == 'DAILY') {
      this.showDiv.Daily = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Fortnightly = false;
      this.currentDate();
    }
    else if (this.selectedVal == 'WEEK') {
      this.disableCurrentDate();
      this.showDiv.Week = true;
      this.showDiv.Fortnightly = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'FORTNIGHT') {
      this.disableCurrentDate();
      this.showDiv.Fortnightly = true;
      this.showDiv.Week = false; this.showDiv.Monthly = false; this.showDiv.Daily = false;
    }
    else if (this.selectedVal == 'MONTH') {
      this.disableCurrentDate();
      this.showDiv.Monthly = true;
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false;
    }
    else {
      this.showDiv.Fortnightly = false; this.showDiv.Week = false; this.showDiv.Daily = false; this.showDiv.Monthly = false;
    }
  }

  onBlurBataWeek(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let validationMsg:any = undefined;
    this.searchParameterModel.fromDate = "";
    this.errorMessage = '';
    if (bataWeekVal.length == 6 && (validationMsg = this._utilsService.monthFortnightValidations(bataWeekVal,searchFlag,fromToFlag)) == 'success') {
      let bataNumber = bataWeekVal.substring(4, 6);
      let bataYear = bataWeekVal.substring(0, 4);
      console.log(bataNumber);
      console.log(bataYear);
      this.payloadBataWeek = {
        "bataYear": bataYear,
        "bataNumber": bataNumber,
        "searchFlag": searchFlag,
        "fromToFlag": fromToFlag
      }
      this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
        (response: any) => {
          if (response.message == "Success") {
            const fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
            this.searchParameterModel.fromDate = fromDateDaily1;
          }
        });
    } else{
        this.errorMessage = validationMsg;
      }
  }

  onSelectPerfix(event) {
    // if(event != undefined){
    //  this.showReportList.Reportname = true;
    // }
    console.log(event);
    this.clearErrorMsg();
    this.selectedList = [];
    this.onlyPerfix.filter(cs => cs.perfix === event).forEach(
      reportkN => {
        this.selectedList.push(reportkN);

      }
    )
    console.log(this.selectedList);

  }

  onBlurBataWeekTo(bataWeekVal, searchFlag, fromToFlag) {
    this.clearErrorMsg();
    console.log(bataWeekVal + '' + searchFlag + '' + fromToFlag);
    let validationMsg:any = undefined;
    this.searchParameterModel.toDate = "";
    this.errorMessage = '';
    if (bataWeekVal.length == 6 && (validationMsg = this._utilsService.monthFortnightValidations(bataWeekVal,searchFlag,fromToFlag)) == 'success') {
      let bataNumber = bataWeekVal.substring(4, 6);
      let bataYear = bataWeekVal.substring(0, 4);
      console.log(bataNumber);
      this.payloadBataWeek = {
        "bataYear": bataYear,
        "bataNumber": bataNumber,
        "searchFlag": searchFlag,
        "fromToFlag": fromToFlag
      }
      this._generateReportService.getRecepitSummary(this.payloadBataWeek).subscribe(
        (response: any) => {
          if (response.message == "Success") {
            let fromDateDaily1 = this._utilsService.transformDateSearch(response.date);
            this.searchParameterModel.toDate = fromDateDaily1;
          }
        });
    }
    else{
        this.errorMessage = validationMsg;
    }
  }

  validDestination(validDes) {
  }

  currentDate() {
    this.clearErrorMsg();
    this.date = new Date();
    this.searchParameterModel.toDate = this._utilsService.transformDate1(this.date);
    this.searchParameterModel.fromDate = this._utilsService.transformDate1(this.date);
    console.log(this.toDate);
  }

  diableSection(){
    this.clearErrorMsg();
    this.showDiv.Week = false;
    this.showDiv.Monthly = false;
    this.showDiv.Fortnightly = false;
    this.showDiv.Daily = false;
    this.responseMessage = '';
    this.sucessMeg1 = '';
    this.errorMessage = "";
  }

disableCurrentDate(){
  this.clearErrorMsg();
    this.searchParameterModel.fromDate = "";
      this.searchParameterModel.toDate = "";
      this.searchParameterModel.periodEndNo = "";
      this.searchParameterModel.periodStartNo = "";
  }
}
