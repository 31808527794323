import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { RdsBilApiUrl } from "../../common/rds-bil-api-url";
import { BilApiUrl } from "../../common/bil-api-url";

@Injectable({
  providedIn: "root",
})
export class PosShortageAdjustmentService {
  pos_stock_adjustment_details_url: string =
    RdsBilApiUrl.POS_STOCK_ADJUSTMENT_DETAILS_URL;
  status_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  concept_division_url: string = RdsBilApiUrl.CONCEPT_DIVISION_URL;
  perform_shortage_adjustment_url: string =
    RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_URL;
  size_url: string = BilApiUrl.MANAGE_SIZE_MASTER;
  perform_shortage_adjustment_for_Claimed_url =
    RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_CLAIMED_URL;
  perform_shortage_adjustment_for_defective_url =
    RdsBilApiUrl.PERFORM_SHORTAGE_ADJUSTMENT_DEFECTIVE_URL;
  config_details_url: string = RdsBilApiUrl.CONFIG_DETAILS_URL;
  status_change_pos_rds_shortage_excess: string = RdsBilApiUrl.STATUS_CHANGE_POS_RDS_SHORTAGE_EXCESS;
  pos_rds_shortage_adjustment_url: string = RdsBilApiUrl.POS_RDS_SHORTAGE_ADJUSTMENT;
  invoiceNumber: any;

  constructor(private _http: HttpClient) {}

  getStockAdjustmentDetails(details) {
    return this._http.post(this.pos_stock_adjustment_details_url, details);
  }

  getshortageAdjustmentDrpdwn(details) {
    return this._http.post(this.status_url, details);
  }

  getsegment(details) {
    return this._http.post(this.concept_division_url, details);
  }

  performShortageAdjustment(details, inventoryType) {
    let url: any;
    if (inventoryType == "CLAIMED") {
      url = this.perform_shortage_adjustment_for_Claimed_url;
    } else if (inventoryType == "DEFECTIVE") {
      url = this.perform_shortage_adjustment_for_defective_url;
    } else {
      url = this.perform_shortage_adjustment_url;
    }
    return this._http.post(url, details);
  }

  getSizeDetails(details) {
    return this._http.post(this.size_url, details);
  }

  getInvoiceStatus() {
    const details: any = [{
      'name': 'paramName',
      'value': 'POS_INVOICE_STATUS'
  }]
    return this._http.post(this.config_details_url, details);
  }

  getInvoiceType() {
    const details: any = [{
      'name': 'paramName',
      'value': 'POS_INVOICE_TYPE'
  }]
    return this._http.post(this.config_details_url, details);
  }


  postPOSRDSStatusChange(body) {
    return this._http.post(this.status_change_pos_rds_shortage_excess, body);
  }

  PosRdsShortageAdjustment(body) {
    return this._http.post(this.pos_rds_shortage_adjustment_url, body);
  }
}
